import { useCallback, useState } from 'react';
import toast from 'react-hot-toast';

import { DateOfBirthValidationBody, Patient } from '@/@types';
import { patientsService } from '@/services';

export function usePatients() {
  const [isLoading, setIsLoading] = useState(false);
  const [patientsOptions, setPatientsOptions] = useState<Patient.Type[]>([]);
  const [patientData, setPatientData] = useState<Patient.Type>();

  const dateOfBirthValidation = useCallback(async (body: DateOfBirthValidationBody) => {
    try {
      await patientsService.dateOfBirthValidation(body);
      return true;
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message);
    }
  }, []);

  const findAllPatients = useCallback(
    async ({ firstName, lastName }: Patient.FindAllPatients.Params) => {
      setIsLoading(true);
      try {
        const params: Patient.FindAllPatients.Params = { firstName, lastName };
        const response = await patientsService.findAllPatients(params);
        const list = response?.data.map((p) => ({
          ...p,
          patientName: `${p.patientName} (${p.dateOfBirth})`,
        }));
        setPatientsOptions(list);
      } finally {
        setIsLoading(false);
      }
    },
    []
  );

  const findPatientById = useCallback(async ({ patientId }: Patient.FindPatientById.Params) => {
    setIsLoading(true);
    let patient = null;
    try {
      const params: Patient.FindPatientById.Params = { patientId };
      const response = await patientsService.findPatientById(params);
      if (response?.data.length) {
        patient = response?.data[0];
      }
    } catch (error: any) {
      toast.error(error?.response?.data?.message || error.message);
    } finally {
      setIsLoading(false);
    }
    return patient;
  }, []);

  const register = useCallback(async (patientId: number, users: Patient.Register.Params) => {
    return patientsService.register(patientId, users);
  }, []);

  const registerContactCMO = useCallback(
    async (id: number, to: string, title: string, body?: string) => {
      return patientsService.registerContactCMO(id, to, title, body);
    },
    []
  );

  return {
    isLoading,
    patientsOptions,
    patientData,
    dateOfBirthValidation,
    findAllPatients,
    findPatientById,
    setPatientData,
    setPatientsOptions,
    register,
    registerContactCMO,
  };
}
